import React from "react";

export default function Hero({ setSocialAside }) {
  return (
    <>
      <main className="pb-40 bg-gray-900">
        <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  <h5 className="text-indigo-500 font-semibold leading-5 tracking-wide ">
                    Hi, my name is
                  </h5>
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">Abraham Kolawole</span>
                    <span className="block text-indigo-400">
                      A Full Stack Software Engineer
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    I am a proficient software engineer specialized in
                    conceptualizing, developing, and delivering dynamic web and
                    mobile solutions tailored to clients' needs. With a robust
                    background in JavaScript and C#, I excel in crafting
                    seamless user experiences. As a seasoned full-stack
                    engineer, I bring expertise in both front-end and back-end
                    development to the table, ensuring holistic and high-quality
                    solutions.
                  </p>
                  <div className="mt-10 sm:mt-12">
                    <div className="sm:flex">
                      <div className="min-w-0 flex-1">
                        <button
                          onClick={() => setSocialAside(true)}
                          className="text-indigo-400 border-indigo-400 border-2 px-4 py-4 text-bold"
                        >
                          Let&apos;s connect
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                  <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="https://tailwindui.com/img/component-images/cloud-illustration-indigo-400.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
