import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import overlayPhoto from "../../../assets/overlayPhoto.png";
import {
  faFacebookSquare,
  faLinkedinIn,
  faTwitter,
  faGoogle,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Socials = ({ setSocialAside, socialAside }) => {
  return (
    <>
      <Transition.Root show={socialAside} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={setSocialAside}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setSocialAside(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full bg-gray-900 p-8 overflow-y-auto">
                    <div className="pb-16 space-y-6">
                      <div>
                        <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                          <img
                            src={overlayPhoto}
                            alt="Abraham Kolawole"
                            className="object-cover"
                          />
                        </div>
                        <div className="mt-4 flex items-start justify-between">
                          <div>
                            <h2 className="text-lg font-medium text-white">
                              <span className="sr-only">Abraham Kolawole</span>
                              Abraham Kolawole
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h3 className="font-medium text-white">
                          Connect with me
                        </h3>
                        <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                          <div className="py-3 flex justify-between text-sm font-medium">
                            <dt className="">
                              <a
                                href="https://www.linkedin.com/in/abraham-kolawole-425b59162"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                  icon={faLinkedinIn}
                                  className="linkedin"
                                />
                              </a>
                            </dt>
                            <dd className="text-white">
                              <a
                                href="https://www.linkedin.com/in/abraham-kolawole-425b59162"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Connect with me
                              </a>
                            </dd>
                          </div>
                          <div className="py-3 flex justify-between text-sm font-medium">
                            <dt className="">
                              <a
                                href="https://github.com/Abraham1999"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                  icon={faGithub}
                                  className="github"
                                />
                              </a>
                            </dt>
                            <dd className="text-white">
                              <a
                                href="https://github.com/Abraham1999"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Follow me
                              </a>
                            </dd>
                          </div>
                          <div className="py-3 flex justify-between text-sm font-medium">
                            <dt className="">
                              <a
                                href="mailto:abrahamkolawole87@gmail.com?subject = Feedback&body = Message"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                  icon={faGoogle}
                                  className="google"
                                />
                              </a>
                            </dt>
                            <dd className="text-white">
                              <a
                                href="mailto:abrahamkolawole87@gmail.com?subject = Feedback&body = Message"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Send an email
                              </a>
                            </dd>
                          </div>
                          <div className="py-3 flex justify-between text-sm font-medium">
                            <dt className="">
                              <a
                                href="https://twitter.com/abraham_kol"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                  icon={faTwitter}
                                  className="twitter"
                                />
                              </a>
                            </dt>
                            <dd className="text-white">
                              <a
                                href="https://twitter.com/abraham_kol"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Follow me
                              </a>
                            </dd>
                          </div>
                          <div className="py-3 flex justify-between text-sm font-medium">
                            <dt className="">
                              <a
                                href="https://www.facebook.com/profile.php?id=100010077757807"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                  icon={faFacebookSquare}
                                  className="facebook"
                                />
                              </a>
                            </dt>
                            <dd className="text-white">
                              <a
                                href="https://www.facebook.com/profile.php?id=100010077757807"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Follow me
                              </a>
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div>
                        <h3 className="font-medium text-white">
                          Request for my Resume / CV
                        </h3>
                      </div>
                      <div></div>
                      <div className="flex">
                        <a
                          href="mailto:abrahamkolawole87@gmail.com?subject = Feedback&body = Message"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center justify-center flex-1 bg-indigo-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="bg-gray-900 pt-24" id="socials">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            <span className="block">Let's get in touch</span>
          </h2>
          <p className="mt-4 text-lg leading-6 text-indigo-400">
            Have any questions or feedback and want to reach out to me, click on
            the button below and message me on any of the social media platforms
            you find
          </p>
          <button
            onClick={() => setSocialAside(true)}
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
          >
            Connect with me
          </button>
        </div>
      </div>
    </>
  );
};

export default Socials;
