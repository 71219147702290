export const LloydsResponsibilities = [
  {
    details:
      "Implement features utilizing C#, .NET Core, and ASP.NET MVC for the backend services and ReactJs for the user interface.",
  },
  {
    details:
      "Leveraged Azure Cloud Services (Azure App Service, Azure SQL Database, Azure Functions) for hosting, managing, and scaling the application in a high-availability environment.",
  },
  {
    details:
      "Collaborated closely with cross-functional teams including UX/UI designers, backend developers, and QA engineers to deliver high-quality, on-time project deliverables.",
  },
  {
    details:
      "Conducted code reviews, provided technical guidance, resulting in improved code quality and team performance.",
  },
  {
    details:
      "Implemented Agile methodologies into development workflows, regularly engaging with cross-functional teams to recommend solutions that improve processes and increase efficiency.",
  },
  {
    details:
      "Collaborated with the product management team to gather requirements, prioritize tasks, and ensure alignment between business needs and technical solutions.",
  },
];

export const loopResponsibilities = [
  {
    details:
      "Creation of various web applications using Typescript for type safety and code maintainability, ReactJS, NextJs, Google Cloud and Firebase.",
  },
  {
    details:
      "Prioritized requirements with the CEO and CTO to discover the optimal product-market fit.",
  },
  {
    details:
      "Conducted code reviews and provided feedback to team members to ensure high-quality code.",
  },
  {
    details:
      "Implemented responsive design and accessibility features to ensure optimal user experience for all users.",
  },
  {
    details:
      "Mentored Junior Developers through weekly training sessions and one on one advising.",
  },
  {
    details:
      "Creation of documentation to explain features to users and design decision to senior management",
  },
  {
    details:
      "Delivering projects and features to strict deadlines from requirements to production use",
  },
];

export const cognotaResponsibilities = [
  {
    details:
      "Collaborated with cross-functional teams, including product and design, to identify requirements, review designs, and execute tasks using Typescript, React, Postgres, AWS, NodeJs.",
  },
  {
    details:
      "Planned and executed software releases on AWS, communicating release plans, timelines, and post-production test results to various teams.",
  },
  {
    details:
      "Implemented rigorous testing methodologies, including unit tests, integration tests, regression tests, and system tests, to validate features and ensure software quality.",
  },
  {
    details:
      "Documented test scenarios and executed test cases to validate software features, ensuring comprehensive test coverage.",
  },
  {
    details:
      "Implemented Agile methodologies into development workflows, regularly engaging with cross-functional teams to recommend solutions that improve processes and increase efficiency.",
  },
  {
    details:
      "Performed code reviews, bug fixing, and led projects such as integrating accessibility testing using Axe into the platform, ensuring compliance with accessibility standards and making the platform accessible to all users.",
  },
];

export const sparkplugResponsibilities = [
  {
    details:
      "Implemented interactive web applications, documenting different stages of product development to ensure transparency and accountability.",
  },
  {
    details:
      "Built scalable templates and maintainable codebases, ensuring optimal performance and seamless user experiences.",
  },
  {
    details:
      "Provided online mentorship to aspiring software engineers in the Sparkplug company community, sharing expertise and knowledge to help others grow in their careers.",
  },
  {
    details:
      "Document API specifications, component libraries, and other relevant technical information.",
  },
  {
    details:
      "Evaluate and implement new tools and technologies to enhance the development process.",
  },
];

export const andelaResponsibilities = [
  {
    details:
      "Provided technical support for nationwide training programs that impacted 1000+ prospective software engineers and also held virtual and physical workshops to train them on Software Development.",
  },
  {
    details:
      "Collaborated with the Andela Talents Partnership team in partnership with Microsoft to anchor the Microsoft 4Afrika Skillslab training program which aimed to equip 3000 graduates from Nigeria with knowledge in advanced technologies and provide vouchers for Azure certification.",
  },
  {
    details:
      "Supported the facilitation of the Gauteng Azure Training program which involved a collaboration between Andela, Microsoft, and the Gauteng government in South Africa to train 3000 graduates with Azure skills.",
  },
  {
    details:
      "Collaborated with Facebook to anchor the #DevCTrainingwithAndela to train 2500 developers across Nigeria and Kenya in web development and also the #BuildforSDG Challenge 2020 which involved the adaptation of the SDG goals into software development projects to upskill developers in Sub-Saharan Africa.",
  },
  {
    details:
      "Collaborated with Google and Pluralsight to anchor the Google Africa Developer Scholarship 2020 to support software developers across Africa in becoming Associate Android Developers, Associate CloudEngineers, or Mobile Web Specialists.",
  },
  {
    details:
      "Utilized Andela learning management systems to process junior software engineers’ transition to senior software engineers through proper progress tracking and documentation.",
  },
  {
    details:
      "Leveraged resources such as Okta and Belvi to expand our reachability across the country and improve customer satisfaction through reliable customer support.",
  },
];

export const axellaResponsibilities = [
  {
    details:
      "Ensured daily optimal performance of the servers, hubs, and routers.",
  },
  {
    details:
      "Interacted with my team and provided assistance on group projects.",
  },
  {
    details:
      "Assist in troubleshooting hardware and software issues for employees.",
  },
  {
    details:
      "Assist in monitoring and maintaining the organization's network infrastructure.",
  },
  {
    details:
      "Learn how to manage server resources, including backups and updates.",
  },
];

export const enmaxResponsibilities = [
  {
    details:
      "Design and implement new user-facing features for public-facing websites and other projects.",
  },
  {
    details:
      "Collaborate with UI/UX designers to ensure technical feasibility of designs.",
  },
  {
    details:
      "Collaborate with cross-functional teams, including designers, product managers, and other developers.",
  },
  {
    details:
      "Design and manage databases to ensure data consistency and security.",
  },
  {
    details:
      "Develop and maintain robust .NET Web API services to support front-end functionalities.",
  },
  {
    details:
      "Integrate APIs with front-end applications to create a seamless user experience.",
  },
  {
    details:
      "Write unit tests, integration tests, and end-to-end tests to ensure application stability.",
  },
];

export const projects = [
  {
    title: "Perfume Closet NG",
    href: "https://perfumeclosetng.herokuapp.com/",
    description:
      "An E-commerce web app used for purchasing perfumes. The aim of this project was to broaden the reach of the company so as to get more clients.",
    stack: "React",
    stackExtra: "Node",
  },
  {
    title: "Great Noble School",
    href: "http://greatnobleschool.herokuapp.com/",
    description:
      "A static web app for a school located in Lagos, Nigeria. This project was built with React, react-bootstrap and hosted on Heroku.",
    stack: "React",
    stackExtra: "React Bootstrap",
  },
  {
    title: "Vice Chancellor Portfolio",
    href: "https://irajayi.vercel.app/",
    description:
      "This static web app was built for Professor Isaac Rotimi Ajayi, the vice chancellor of Crawford University from 2016-2020.",
    stack: "React",
    stackExtra: "Reactstrap",
  },
  {
    title: "Unitix",
    href: "https://unitixng.herokuapp.com/",
    description:
      "Unitix connects students with events occurring in universities all over Nigeria.",
    stack: "MERN",
    stackExtra: "Redux",
  },
  {
    title: "OnlyFoodsWA",
    href: "https://onlyfoodswa.herokuapp.com/",
    description:
      "OnlyFoods is a platform that allows users to send money to friends to sponsor their meals in their favorite restaurants",
    stack: "MERN",
    stackExtra: "Redux",
  },
  {
    title: "Stock Analysis",
    href: "https://stock-analysis-blog.herokuapp.com/",
    description:
      "This web app is used to enable stock analysts add Tickers for a stock.",
    stack: "MERN",
    stackExtra: "Redux",
  },
  {
    title: "Sparkplug Kids",
    href: "https://sparkplugkids.vercel.app/",
    description:
      "This is the static web app for the Sparkplug kids educational platform.",
    stack: "React",
    stackExtra: "Tailwind",
  },
  {
    title: "MERN Redux Template",
    href: "https://globaldeliverycompany.herokuapp.com/",
    description:
      "This is a template built with React, Redux, Express, MongoDB, NodeJs.",
    stack: "MERN",
    stackExtra: "Redux",
  },
];
