import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import MeSmiling from "../../assets/post_pic.png";
import moment from "moment";

export default function AdminBlog() {
  const [Posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = () => {
    Axios.get(`${process.env.REACT_APP_VERCEL_URL}/posts`).then((response) => {
      if (response.data) {
        setPosts(response.data);
      } else {
        alert("Failed to fetch data");
      }
    });
  };
  return (
    <div className="App relative bg-gray-900 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-gray-900 h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="">
          <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-indigo-400 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
            Admin Blog
          </h1>
          <a
            href="/admin/add-post"
            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
          >
            Add post
          </a>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {Posts.map((post) => (
            <Link
              to={`/admin/edit-post`}
              state={{ post: post }}
              key={post._id}
              className="hover:shadow-lg hover:border-indigo-400 bg-gray-800 flex flex-col border border-gray-500 rounded-3xl shadow-lg overflow-hidden"
            >
              <div className="p-6 rounded-3xl flex-shrink-0">
                <img
                  className=" rounded-3xl h-48 w-full object-cover"
                  src={post.images[0].url}
                  alt="Post cover"
                />
              </div>
              <div className="flex-1 bg-gray-800 px-6 flex flex-col justify-between">
                <div className="flex-1">
                  <ul>
                    {post.category.slice(0, 4).map((category, index) => (
                      <li
                        key={index}
                        className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                      >
                        {category}
                      </li>
                    ))}
                  </ul>

                  <p className="block mt-2 text-xl font-semibold text-white">
                    {post.title}
                  </p>
                </div>
                <div className=" py-4 flex items-center">
                  <div className="flex-shrink-0">
                    <span className="sr-only">Abraham Kolawole</span>
                    <img
                      className="h-10 w-10 rounded-full"
                      src={MeSmiling}
                      alt="Abraham Kolawole"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white">
                      Abraham Kolawole
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-400">
                      <span className="">
                        {moment(post.createdAt).startOf("HH:mm").fromNow()}
                      </span>{" "}
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.timeToRead} mins read</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
