import React, { useState } from "react";
import Cognota from "./Cognota";
import Sparkplug from "./Sparkplug";
import Andela from "./Andela";
import AndelaLogo from "../../../assets/Andela.png";
import SparkPlugLogo from "../../../assets/logo.png";
import CognotaLogo from "../../../assets/CognotaLogo.png";
import LoopLogo from "../../../assets/loopnotluck.png";
import Loop from "./Loop";
import LloydsLogo from "../../../assets/Lloyds-logo.png";
import EnmaxLogo from "../../../assets/EnmaxLogo.png";

import Lloyds from "./Lloyds";
import Enmax from "./Enmax";
const tabs = [
  { name: "Enmax", image: EnmaxLogo, style: "h-16 w-24" },
  {
    name: "Lloyds Banking Group",
    image: LloydsLogo,
    style: "h-16 w-24 bg-white pb-2",
  },
  { name: "Loop Not Luck", image: LoopLogo, style: "bg-white h-16 w-24 t-2" },
  { name: "Cognota", image: CognotaLogo, style: "h-16 w-24" },
  { name: "Sparkplug", image: SparkPlugLogo, style: "h-16 w-24" },
  { name: "Andela", image: AndelaLogo, style: "h-16 w-24" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Experience = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const [formData, setFormData] = useState("");

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const handleRouteMobile = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const { value } = e.target;
    setActiveTab(value);
  };

  return (
    <main className="bg-gray-900" id="experience">
      <>
        <div className="pt-40 max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="px-4 sm:px-0">
            <h2 className="text-sm pb-12 font-semibold tracking-wide line-after">
              <span className="font-extrabold text-3xl text-indigo-400">
                Experience
              </span>
            </h2>

            {/* Tabs */}
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                onChange={(e) => handleRouteMobile(e)}
              >
                <option value="Enmax">{tabs[0].name}</option>
                <option value="Lloyds Banking Group">{tabs[1].name}</option>
                <option value="Loop Not Luck">{tabs[2].name}</option>
                <option value="Cognota">{tabs[3].name}</option>
                <option value="Sparkplug">{tabs[4].name}</option>
                <option value="Andela">{tabs[5].name}</option>
              </select>
            </div>

            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={(e) => handleChangeTab(tab.name)}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-400 bg-indigo-800/20"
                          : "border-transparent text-gray-400 hover:text-indigo-400 hover:bg-indigo-800/20 hover:border-gray-300",
                        "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                      )}
                      aria-current={tab.name === activeTab ? "page" : undefined}
                    >
                      {tab.name}
                      <img
                        className={`my-4 px-1 py-1 text-center mx-auto  ${tab.style}`}
                        src={tab.image}
                        alt="Lloyds Banking Group"
                      />
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          <section aria-labelledby="experience">
            {activeTab === tabs[0].name ? (
              <Enmax />
            ) : activeTab === tabs[1].name ? (
              <Lloyds />
            ) : activeTab === tabs[2].name ? (
              <Loop />
            ) : activeTab === tabs[3].name ? (
              <Cognota />
            ) : activeTab === tabs[4].name ? (
              <Sparkplug />
            ) : activeTab === tabs[5].name ? (
              <Andela />
            ) : null}
          </section>
        </div>
      </>
    </main>
  );
};

export default Experience;
