import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./PageNotFound";
import Portfolio from "./Pages";
import Login from "./Pages/admin/Login";
import AddPost from "./Pages/admin/AddPost";
import AllPosts from "./Pages/blog/AllPosts";
import { loadUser } from "./redux/actions/auth";
import store from "./redux/store/store";
import PostDetails from "./Pages/blog/Post";
import AdminBlog from "./Pages/admin/AllPosts";
import PrivateRoute from "./utils/PrivateRoute";
import ReactGA from 'react-ga';

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  
  useEffect(() => {
    ReactGA.initialize('G-G13TLK5Z8C');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter basename='/'>
      <Routes>
        <Route exact path="/" element={<Portfolio />} />
        <Route exact path="/add-blog" element={<Portfolio />} />
        <Route exact path="/admin" element={<Login />} />
        <Route
          exact
          path="/admin/add-post"
          element={
            <PrivateRoute>
              <AddPost />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/admin/edit-post"
          element={
            <PrivateRoute>
              <AddPost />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/admin/blog"
          element={
            <PrivateRoute>
              <AdminBlog />
            </PrivateRoute>
          }
        />
        <Route exact path="/blog" element={<AllPosts />} />
        <Route exact path="/blog/:id" element={<PostDetails />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
