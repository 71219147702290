import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import Fade from "react-reveal/Fade";

const metrics = [
  {
    id: 1,
    stat: "React",
  },
  {
    id: 2,
    stat: "React Native",
  },
  {
    id: 3,
    stat: "Redux",
  },
  {
    id: 4,
    stat: "JavaScript",
  },
  {
    id: 5,
    stat: "TypeScript",
  },
  {
    id: 6,
    stat: "Node.js",
  },
  {
    id: 7,
    stat: "PostgreSQL",
  },
  {
    id: 8,
    stat: "C#",
  },
  {
    id: 9,
    stat: "ASP.NET, .NET Core",
  },
  {
    id: 10,
    stat: "AWS",
  },
  {
    id: 10,
    stat: "Azure",
  },
  {
    id: 11,
    stat: "MongoDB",
  },
];

const About = () => {
  return (
    <div className="relative bg-gray-900" id="about">
      <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <img
              className="h-full w-full object-cover opacity-50 xl:absolute xl:inset-0"
              src={require("../../../assets/hero-bg.png")}
              alt="People working on laptops"
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <Fade left>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
          <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
            <h2 className="text-sm font-semibold tracking-wide line-after">
              <span className="font-extrabold text-3xl text-indigo-400">
                About Me
              </span>
            </h2>
            <p className="mt-5 text-lg text-gray-300">
              My name is Abraham Kolawole, a Computer Science graduate. As an
              effective full stack and quality assurance engineer, I have proven
              experience in web development and have consistently coordinated
              projects to reach the users satisfaction and also taken leadership
              positions to enhance my leadership capabilities , I have gained
              experience coding with Javascript, React, TypeScript, Node, C#,
              ASP.Net, Devops, postgreSQl, mongodb, Firebase, etc.
              <br />
              <br />
              Here are a few technologies I’ve been working with recently:
            </p>

            <div className="mt-2 grid grid-cols-3 gap-y-5 gap-x-6 sm:grid-cols-2">
              {metrics.map((item) => (
                <p key={item.id} className="flex">
                  <ChevronRightIcon
                    className="pt-1 w-4 h-4 text-indigo-400"
                    aria-hidden="true"
                  />
                  <span className="flex text-sm font-bold text-indigo-400">
                    {item.stat}
                  </span>
                </p>
              ))}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default About;
