import { useEffect, useState } from "react";
import PortfolioFooter from "./Footer";
import About from "./sections/About";
import Experience from "./sections/Experience";
import Hero from "./sections/Hero";
import Navbar from "./sections/Navbar";
import Projects from "./sections/Projects";
import Socials from "./sections/Socials";
import { useLocation } from "react-router-dom";

const PortfolioMain = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const [socialAside, setSocialAside] = useState(false);
  return (
    <div className="min-h-screen App">
      <div className="relative overflow-hidden">
        <Navbar />
        <Hero setSocialAside={setSocialAside} />
        <About />
        <Experience />
        <Projects />
        <Socials socialAside={socialAside} setSocialAside={setSocialAside} />
        <PortfolioFooter />
      </div>
    </div>
  );
};

export default PortfolioMain;
