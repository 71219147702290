import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { cognotaResponsibilities } from "../../../utils/data";

const Cognota = () => {

  return (
    <div className="bg-gray-900 shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="applicant-information-title"
          className="text-2xl leading-6 font-medium text-white"
        >
          Software Engineer{" "}
          <a
            href="https://cognota.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-400"
          >
            @ Cognota
          </a>
        </h2>
        <p className="mt-2 max-w-2xl text-sm text-gray-300">
          June 2021 - July 2023
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <ul className="w-full rounded-lg mt-2 mb-3">
          {cognotaResponsibilities.map((item) => (
            <li className="mb-2" key={item.details}>
              <p className="w-full flex text-white rounded-lg">
                <ChevronRightIcon
                  className="flex-none w-6 h-full text-indigo-400"
                  aria-hidden="true"
                />
                <p className="pl-4">{item.details}</p>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Cognota;
