import axios from "axios";
import { USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL } from "./types";
import setAuthToken from "../../utils/setAuthToken";

//Load user
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/user/auth`);

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login user
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post(`${process.env.REACT_APP_VERCEL_URL}/user/signin`, body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    if (err.response.data.errors[0].msg === "Invalid Credentials") {
      alert("Wrong Email or Password");
    } else if (err.response.data.errors[0].msg === "Error with email") {
      alert("Problem with your email. Try Google Login");
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};
