import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../assets/Spinner.gif";
import { getPost } from "../../redux/actions/posts";
import { useParams } from "react-router-dom";

import PostItem from "./PostItem";

const PostDetails = ({ getPost, posts: { post, loading } }) => {
  const { id } = useParams();
  useEffect(() => {
    getPost(id);
  }, [getPost, id]);

  return loading || post === null ? (
    <img src={Spinner} alt='loading post' />
  ) : (
    <Fragment>
      <PostItem post={post} />
    </Fragment>
  );
};

PostDetails.propTypes = {
  getPost: PropTypes.func.isRequired,
  posts: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  posts: state.posts,
});

export default connect(mapStateToProps, { getPost })(PostDetails);
